/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Container, Row } from 'react-bootstrap';
import MyNavbar from '../../components/MyNavbar';
import { FaChevronLeft, FaSpinner } from 'react-icons/fa';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../states';
import { PostPublic, PostVCR } from '../../services/Connection';
import { toast } from 'react-toastify';
import Axios from 'axios';
// import ClampLines from 'react-clamp-lines'
import PouchDB from 'pouchdb';
import MyFooter from '../../components/MyFooter';
import { AppConfig } from '../../config';
import { Helmet } from 'react-helmet';
import ModalVoucherDesc from '../ModalVoucherDesc';
import LoadECard from '../../components/LoadECard';
import { GetShippingCost, loadGiftById, loadGifts } from '../../services/LegacyService';
import ModalConfirm from './components/ModalConfim';
import ModalShipment from './components/ModalShipment';
import MerchantInfo from './components/MerchantInfo';
import ModalMerchantTnc from './components/ModalMerchantTnc';
import Gifts from './components/Gifts';
import MyCart from './components/MyCart';
import TabBarBottom from '../../components/TabBarBottom';
import GiftBoxView from './components/GiftBoxView';
import MerchantProducts from './components/MerchantProducts';
import ProductInfo from './components/ProductInfo';
import MyImg from '../../components/MyImg';
import Clamp from 'react-multiline-clamp';
import { CurrencyFormat } from '../../helper/CurrencyFormat';
import { Modal } from 'react-bootstrap';
// import Clamp from 'react-multiline-clamp';

const Merchant = () => {
  const { state, dispatch } = useContext(AppContext);
  const { merchantId, giftId } = useParams();
  const history = useHistory();

  const { isRedeem, gifts, activeMerchant, activeMerchantProducts, selectedGift, titlePage } =
    state.merchantPage;

  useEffect(() => {
    dispatch({
      type: 'MERCHANT_PAGE',
      payload: {
        showConfirm: false,
      },
    });
    if (state.gifts) {
      let merchantGifts = state.gifts.filter((gift) => {
        if (merchantId) return gift.merchant.id === merchantId;
        else return gift.id === giftId;
      });
      if (giftId && merchantGifts.length === 0) {
        // try to find inside products
        state.gifts
          .filter((g) => g.products && g.products.length > 0)
          .forEach((g) => {
            g.products
              .filter((gg) => gg.id === giftId)
              .forEach((gg) => {
                if (!gg.merchant) gg.merchant = g.merchant;
                merchantGifts.push(gg);
              });
          });
      }
      // do not filter when gift box
      if (state.ecard.is_gift_box == 1) {
        merchantGifts = [...state.gifts];
      }
      // console.log('merchant gifts', merchantGifts);
      // alert('stop');
      let initialGifts = [];
      merchantGifts.forEach((gift) => {
        if (gift.is_cash_voucher === 1 && gift.products) {
          gift.products.forEach((p) => {
            initialGifts.push({ ...p, quantity: 0 });
          });
        } else {
          initialGifts.push({
            ...gift,
            quantity: 0,
          });
        }
      });
      if (initialGifts.length === 1) {
        initialGifts[0].quantity = 1;
      }
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          gifts: initialGifts,
          selectedGift: giftId ? merchantGifts[0] : undefined,
        },
      });
      if (merchantGifts && merchantGifts.length > 0) {
        dispatch({
          type: 'MERCHANT_PAGE',
          payload: {
            activeMerchant: {
              ...merchantGifts[0].merchant,
              expiration: merchantGifts[0].expiration,
            },
            activeMerchantProducts: state.gifts.filter(
              (g) => g.merchant.id === merchantGifts[0].merchant.id
            ),
            titlePage: merchantGifts[0].merchant.name + ' -',
          },
        });
      }
    }
  }, [dispatch, giftId, merchantId, state.gifts, state.ecard]);

  useEffect(() => {
    const getGifts = async () => {
      // load gifts from local db
      let allDocs = [];
      try {
        let db = new PouchDB('redemption-gift-result-' + state.ecard.company.id);
        let res = await db.allDocs({ include_docs: true });
        allDocs = res.rows.map((i) => i.doc).sort((a, b) => a.price - b.price);
      } catch (errGetFromLocalDB) {
        console.log('error load from local db', errGetFromLocalDB);
      }
      if (allDocs.length === 0 || state.ecard.is_gift_box == 1) {
        if (giftId) {
          // load from catalog by id
          dispatch({
            type: 'MERCHANT_PAGE',
            payload: {
              gifts: [],
              activeMerchant: {},
              titlePage: '',
            },
          });

          if (state.ecard.is_gift_box == 1) {
            let res = await loadGifts(
              state.ecard && state.ecard.company && state.ecard.company && state.ecard.company.id
                ? state.ecard.company.id
                : '',
              0,
              8,
              state.ecard.vouchertypeid,
              state.ecard.country || 'SG'
            );
            if (res.status === 1) {
              const giftData = res.data.products
                ?.filter((prod) => parseFloat(prod.price) <= parseFloat(state.ecard.balance))
                .map((prod) => ({
                  ...prod,
                  merchant: res.data.merchants[prod.merchant_id],
                }));
              dispatch({
                type: 'MERCHANT_PAGE',
                payload: { gifts: giftData },
              });
            } else {
              toast.warning(res.message || 'Oops, something wrong while load gifts');
            }
          } else {
            loadGiftById(state.ecard.company.id, giftId, state.ecard.country).then((resVoucher) => {
              // console.log('res voucher', resVoucher)
              if (resVoucher.status === 1) {
                let products = resVoucher.data.products.map((product) => {
                  let item = { ...product };
                  item.merchant = resVoucher.data.merchants[product.merchant_id];
                  return item;
                });
                let initialGifts = [];
                products.forEach((gift) => {
                  if (gift.is_cash_voucher === 1) {
                    gift.products.forEach((p) => {
                      initialGifts.push({ ...p, quantity: 0 });
                    });
                  } else {
                    initialGifts.push({ ...gift, quantity: 0 });
                  }
                });
                // setGifts(initialGifts)
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    gifts: initialGifts,
                    selectedGift: products[0],
                  },
                });
                if (products.length > 0) {
                  // console.log('set merchant',products[0].merchant)
                  // setActiveMerchant(products[0].merchant)
                  // setTitlePage(products[0].merchant.name + ' - ')
                  dispatch({
                    type: 'MERCHANT_PAGE',
                    payload: {
                      activeMerchant: products[0].merchant,
                      titlePage: products[0].merchant.name + ' - ',
                      expiration: products[0].expiration,
                    },
                  });
                }
              } else {
                toast.error(
                  'Oops.. something wrong while loading gift data, please reload the page to try again!'
                );
              }
            });
          }
        } // end if gift id param
      } else {
        dispatch({
          type: 'SET_GIFTS',
          data: allDocs,
        });
      }
    };
    if (state.ecard && !state.gifts) {
      getGifts();
    }
  }, [dispatch, giftId, state.ecard, state.gifts]);

  useEffect(() => {
    if (giftId && state.merchantPage.gifts && state.merchantPage.gifts.length > 0) {
      let idx = 0;
      state.merchantPage.gifts.forEach((i, ii) => {
        if (i.id === giftId) {
          idx = ii;
        }
      });
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          activeGift: idx,
        },
      });
    }
  }, [dispatch, giftId, state.merchantPage.gifts]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [giftId, merchantId]);

  if (!state.whitelabel || !state.gcardinfo) return <Redirect to='/' />;

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;

  const redeemClick = async (selectedGifts) => {
    if (!selectedGifts) {
      selectedGifts = gifts.filter((gift) => gift.quantity > 0);
    }
    // console.log('selected gifts', selectedGifts)
    if (selectedGifts.length === 0) {
      toast('Please select at least one gift to buy!', { type: 'warning' });
      return;
    }

    // let country = selectedGifts[0].country.code;
    // let currency = selectedGifts[0]['currency-code'];
    let country = state.ecard.country;
    let currency = state.ecard.currency;

    // let not_evoucher = false
    // selectedGifts.forEach((gift) => {
    //   if (gift.evoucher !== '1') {
    //     not_evoucher = true
    //   }
    // });
    let no_email = false;
    selectedGifts.forEach((gift) => {
      const dymethods = gift.dymethods.map((d) => d.value);
      if (!dymethods.includes('email')) no_email = true;
    });

    //jika is_physical == 1  && dymethods tidak ada email show modal
    //dy method set courier
    if (no_email) {
      // setShowModal(true)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          showModalShipment: true,
          showConfirm: false,
        },
      });
    } else {
      // console.log('process redeem')

      // setIsRedeem(true)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          showConfirm: false,
          isRedeem: true,
        },
      });

      try {
        // get shopping cart id
        let getShoppingcartValue = {
          marketCountry: country,
          currency: currency,
          'sales-channel': 'giftano-card-redemption',
        };

        if (state.member && state.member.id) {
          getShoppingcartValue.memberid = state.member.id;
        }

        let resp = await PostPublic('get_shoppingcartid/', getShoppingcartValue);
        // console.log('shopping cart id ', resp)
        if (resp.status === 1) {
          let shoppingcartid = resp.data.id;

          for (let gift of selectedGifts) {
            let respAddCartItem = await PostPublic('add_shoppingcartitem/', {
              merchantid: activeMerchant.id,
              shoppingcartid: shoppingcartid,
              dymethod: 'email',
              quantity: gift.quantity,
              vouchertype: gift.id,
            });
            // console.log('result add cart ', respAddCartItem)
            // eslint-disable-next-line eqeqeq
            if (respAddCartItem.status == 0) {
              toast('Can not add gift to shopping cart, ' + respAddCartItem.message, {
                type: 'warning',
              });
              // console.log('result add cart ', respAddCartItem)
              // setIsRedeem(false)
              dispatch({
                type: 'MERCHANT_PAGE',
                payload: {
                  isRedeem: false,
                },
              });
              return;
            }
          }

          //lock the cart
          let valueLock = {
            shoppingcartid: shoppingcartid,
          };
          if (state.member && state.member.id) {
            valueLock.memberid = state.member.id;
          }
          await PostPublic('lock_shoppingcart/', valueLock);
          // console.log('resp lock shopping cart', respLock)

          //get ip client
          let ipClient = '';
          try {
            let respIp = await Axios('https://giftano-tools.pages.dev/api/ipinfo?format=json');
            if (respIp.status === 200) {
              ipClient = respIp.data.ip;
            }
          } catch (err) {}

          //pay
          let respPay = await PostVCR('createtransaction_with_ecard/', {
            ecard: state.ecard.number,
            shoppingcartid: shoppingcartid,
            ipaddress: ipClient,
          });
          // console.log('response payment', respPay)
          if (respPay.status === 1) {
            //success reload ecard
            let respEcard = await PostPublic('ecard/', {
              ecard: state.ecard.number,
              key: state.key,
              details: 1,
            });
            // console.log('resp ecard more ', resp)
            if (respEcard.status === 1) {
              dispatch({ type: 'SET_ECARD', data: respEcard.data });
            } else {
              toast.warning(
                'Something wrong while loading gift data, please reload the page to show your gift'
              );
            }
            let receipt = respPay.data;
            history.push('/mygifts/' + receipt);
          } else {
            if (respPay.message) {
              toast('Unable to process redemtion, ' + respPay.message, {
                type: 'warning',
              });
            } else {
              toast(
                'Unable to process redemption, please try again or contact Gitano customer support.',
                { type: 'warning' }
              );
            }
            //console.error('error create transaction')
          }
        } else {
          if (resp.message) {
            toast('Unable to process redemption, ' + resp.message, {
              type: 'warning',
            });
          } else {
            toast(
              'Unable to process redemption, please try again or contact Gitano customer support.',
              { type: 'warning' }
            );
          }
          //console.error('error get shopping cart')
        }
      } catch (err) {
        console.log('error redeem', err);
        toast(
          'Unable to process redemption, please try again or contact Gitano customer support.',
          { type: 'warning', onClose: () => window.location.reload() }
        );
      }

      // setIsRedeem(false)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          isRedeem: false,
        },
      });
    }
  };

  const grand_total =
    gifts.length > 0
      ? gifts
          .map(
            (gift) =>
              gift.quantity * parseFloat(gift.price) +
              (gift.quantity ? parseFloat(gift.primary_extrafirst) : 0)
          )
          .reduce((total, price) => total + price)
      : 0;

  const merchantOtherGifts = activeMerchantProducts
    ? activeMerchantProducts.filter((g) => g.id !== giftId)
    : [];
  return (
    <div className='page-merchant'>
      <Helmet>
        <title>{titlePage + AppConfig.title}</title>
      </Helmet>
      <div className=''>
        <LoadECard>
          {state.ecard && (
            <>
              {isRedeem && (
                <Modal show={true} backdrop={'static'} centered size='sm'>
                  <Modal.Body>
                    <div>
                      <div className='text-center mb-2 text-primary' style={{ fontSize: '36px' }}>
                        <FaSpinner className='icon-spin' />
                      </div>
                      <div className='text-center mb-4' style={{ fontSize: '24px' }}>
                        Processing your order
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              <MyNavbar />
              <Container className={'merchant-container' + (hidePrice ? ' Hprice' : ' Wprice')}>
                {state.ecard.is_gift_box == 1 ? (
                  <GiftBoxView />
                ) : (
                  <>
                    <Row className='mb-2'>
                      <Col md='12'>
                        <Breadcrumb>
                          <Breadcrumb.Item
                            onClick={() => {
                              if (history.length > 0) {
                                history.goBack();
                              } else {
                                history.push('/search');
                              }
                            }}
                          >
                            <FaChevronLeft className='mr-1' />
                            Back
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>
                            {merchantId
                              ? activeMerchant.name
                              : selectedGift
                              ? selectedGift.name
                              : ''}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      </Col>
                    </Row>

                    {gifts.length === 0 ? (
                      <div
                        style={{
                          fontSize: '28px',
                          color: 'gray',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '50vh',
                        }}
                      >
                        <FaSpinner className='icon-spin mr-2' /> Loading...
                      </div>
                    ) : (
                      <Row>
                        <Col md='12'>
                          {merchantId ? (
                            <>
                              <MerchantInfo />
                              <MerchantProducts merchantId={merchantId} />
                            </>
                          ) : (
                            <>
                              {selectedGift &&
                              selectedGift.products &&
                              selectedGift.products.length > 1 ? (
                                <ProductInfo />
                              ) : null}
                              <Row className='merchant-product__list'>
                                <Col
                                  lg={
                                    !hidePrice
                                      ? selectedGift &&
                                        selectedGift.products &&
                                        selectedGift.products.length > 1
                                        ? 9
                                        : 12
                                      : state.ecard.is_gift_box == 1
                                      ? 7
                                      : 12
                                  }
                                  md={12}
                                >
                                  <Gifts />
                                </Col>

                                {!hidePrice ? (
                                  selectedGift &&
                                  selectedGift.products &&
                                  selectedGift.products.length > 1 ? (
                                    <Col lg={3} md={12}>
                                      <Card.Title className='mb-0'>Summary</Card.Title>
                                      <div
                                        className='cart'
                                        style={{
                                          marginBottom:
                                            grand_total > 0 && window.innerWidth < 768
                                              ? '10em'
                                              : '1em',
                                          height:
                                            grand_total === 0 &&
                                            window.matchMedia('(max-width: 767px)').matches
                                              ? '175px'
                                              : 'auto',
                                        }}
                                      >
                                        <MyCart />
                                        {/* Buy Button */}
                                        <Card
                                          style={{
                                            // boxShadow: inView
                                            //   ? `none`
                                            //   : `-1px -4px 4px -6px #3f3f3f`,
                                            position:
                                              grand_total > 0 && window.innerWidth < 1024
                                                ? 'fixed'
                                                : 'relative',
                                            // bottom: '80px',
                                            bottom: '80px',
                                            boxShadow:
                                              grand_total > 0 && window.innerWidth < 1024
                                                ? 'var(--gf-shadow-thick)'
                                                : 'none',
                                          }}
                                          className='card-total merchant__buy'
                                        >
                                          <Card.Body>
                                            <div className='d-flex cart__container align-items-center'>
                                              <h4 className='mr-auto cart__key'>
                                                <span className='desktop'>Total Gifts Order</span>
                                                <span className='mobile'>Total</span>:
                                              </h4>
                                              <h4>
                                                <CurrencyFormat value={grand_total} />
                                              </h4>
                                              <Button
                                                className='cart__action d-sm-block d-md-none'
                                                onClick={() => {
                                                  // if (
                                                  //   grand_total >
                                                  //   state.ecard.balance
                                                  // ) {
                                                  //   let topupValue =
                                                  //     grand_total -
                                                  //     parseFloat(
                                                  //       state.ecard.balance
                                                  //     );
                                                  //   //round up 2 digit
                                                  //   topupValue =
                                                  //     Math.ceil(
                                                  //       (topupValue +
                                                  //         Number.EPSILON) *
                                                  //         100
                                                  //     ) / 100;
                                                  //   if (topupValue <= 200) {
                                                  //     dispatch({
                                                  //       type: 'SHOW_TOPUP',
                                                  //       topupAmount: topupValue,
                                                  //       topupAction: () =>
                                                  //         redeemClick(),
                                                  //     });
                                                  //   } else {
                                                  //     toast.warning(
                                                  //       'Your purchase exceeding maximum amount, please change to another product or contact us.'
                                                  //     );
                                                  //   }
                                                  // } else {
                                                  // setShowConfirm(true)
                                                  dispatch({
                                                    type: 'MERCHANT_PAGE',
                                                    payload: {
                                                      showConfirm: true,
                                                    },
                                                  });
                                                  //  }
                                                }}
                                                disabled={isRedeem || grand_total === 0}
                                              >
                                                {isRedeem && (
                                                  <FaSpinner className='mr-2 icon-spin' />
                                                )}
                                                BUY
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                        <Button
                                          className='cart__action desktop'
                                          onClick={() => {
                                            // if (
                                            //   grand_total > state.ecard.balance
                                            // ) {
                                            //   if (
                                            //     !state.ecard.total_topup ||
                                            //     state.ecard.total_topup < 1
                                            //   ) {
                                            //     let topupValue =
                                            //       grand_total -
                                            //       parseFloat(
                                            //         state.ecard.balance
                                            //       );
                                            //     //round up 2 digit
                                            //     topupValue =
                                            //       Math.ceil(
                                            //         (topupValue +
                                            //           Number.EPSILON) *
                                            //           100
                                            //       ) / 100;
                                            //     if (topupValue <= 200) {
                                            //       dispatch({
                                            //         type: 'SHOW_TOPUP',
                                            //         topupAmount: topupValue,
                                            //         topupAction: () =>
                                            //           redeemClick(),
                                            //       });
                                            //     } else {
                                            //       toast.warning(
                                            //         'Your purchase exceeding maximum amount, please change to another product or contact us.'
                                            //       );
                                            //     }
                                            //   } else {
                                            //     toast.warning(
                                            //       'You have exceeding maximum top-up limit.'
                                            //     );
                                            //   }
                                            // } else {
                                            // setShowConfirm(true)
                                            dispatch({
                                              type: 'MERCHANT_PAGE',
                                              payload: {
                                                showConfirm: true,
                                              },
                                            });
                                            // }
                                          }}
                                          disabled={isRedeem || grand_total === 0}
                                        >
                                          {isRedeem && <FaSpinner className='mr-2 icon-spin' />}
                                          BUY
                                        </Button>
                                      </div>
                                    </Col>
                                  ) : null
                                ) : null}
                              </Row>

                              {/* Merchant Widget */}
                              <Row className='mt-2 mb-4 merchant-information'>
                                <Col sm={3}>
                                  <MyImg
                                    src={activeMerchant.logo}
                                    className={'img-fluid'}
                                    onClick={() => {
                                      history.push(`/merchant/${activeMerchant.id}`);
                                    }}
                                  />
                                </Col>
                                <Col sm={9}>
                                  <h3
                                    className='merchant-information__title'
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                      history.push(`/merchant/${activeMerchant.id}`);
                                    }}
                                  >
                                    About {activeMerchant.name}
                                  </h3>
                                  <Clamp lines={4}>
                                    <p className='merchant-information__desc'>
                                      {activeMerchant.desc}
                                    </p>
                                  </Clamp>
                                </Col>
                              </Row>

                              {merchantOtherGifts.length > 0 && (
                                <Row>
                                  <Col md={12} className='merchant-misc'>
                                    <div className='d-flex justify-content-between align-items-baseline mb-3'>
                                      <h5 className='merchant-misc__title'>
                                        Other Gift(s) by {activeMerchant.name || 'this merchant'}
                                      </h5>
                                      <Button
                                        className='merchant-misc__view-all'
                                        variant='link'
                                        onClick={() => {
                                          history.push('/merchant/' + activeMerchant.id);
                                        }}
                                      >
                                        View All
                                      </Button>
                                    </div>
                                    <div
                                      style={{
                                        overflowX: 'scroll',
                                        marginBottom: '30px',
                                        paddingLeft: '10px',
                                      }}
                                    >
                                      <Row style={{ flexWrap: 'nowrap' }}>
                                        {merchantOtherGifts.map((gift) => (
                                          <Col
                                            lg={3}
                                            md={3}
                                            sm={5}
                                            xs={5}
                                            key={'itemOtherGift' + gift.id}
                                          >
                                            <Card>
                                              <MyImg className={'img-fluid'} src={gift.file} />
                                              <Card.Body>
                                                <Card.Title>{gift.name}</Card.Title>
                                                {gift.is_cash_voucher == 1 ? (
                                                  <p>
                                                    From{' '}
                                                    <CurrencyFormat value={gift['min-price']} />
                                                  </p>
                                                ) : (
                                                  <p>
                                                    <CurrencyFormat value={gift.price} />
                                                  </p>
                                                )}
                                                <Button
                                                  style={{
                                                    padding: '10px',
                                                  }}
                                                  block
                                                  onClick={() => {
                                                    history.push('/gift/' + gift.id);
                                                  }}
                                                >
                                                  View Gift
                                                </Button>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    )}
                  </>
                )}

                <ModalMerchantTnc />
                <ModalVoucherDesc />
                <ModalConfirm
                  confirmAction={async () => {
                    if (state.ecard.is_gift_box == '1' || hidePrice) {
                      //get selected
                      const selectedItem = state.merchantPage.gifts[state.merchantPage.activeGift];
                      selectedItem.quantity = 1;
                      selectedItem.primary_extrafirst = await GetShippingCost(
                        selectedItem,
                        state.ecard.company.id
                      );
                      redeemClick([selectedItem]);
                    } else {
                      redeemClick();
                    }
                  }}
                />
                <ModalShipment />
              </Container>
              <TabBarBottom />
            </>
          )}
        </LoadECard>
        <MyFooter />
      </div>
    </div>
  );
};

export default Merchant;
